<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
    export let src: string;
    export let isTransparent = false;
</script>

{#if !isTransparent}
    <picture>
        <source srcset="{src}.avif" type="image/avif" />
        <source srcset="{src}.webp" type="image/webp" />
        <img src="{src}.jpg" {...$$restProps}>
    </picture>
{:else}
    <picture>
        <source srcset="{src}.avif" type="image/avif" />
        <source srcset="{src}.webp" type="image/webp" />
        <img src="{src}.png" {...$$restProps}>
    </picture>
{/if}

<style lang="scss">
    picture {
        display: contents;;
    }
</style>